.highlights-logo {
  margin-right: -10px;
  img {
    display: block;
    margin: 2px auto 4px;
    max-height: 50px;
    border: 1px solid var(--bs-gray-300);
  }
  svg {
    width: 25px;
    height: 25px;
    margin: auto;
    display: block;
  }
}

.highlights-row {
  font-size: 90%;
  align-items: center;
}

.highlights-row:hover,
.program-row:hover:not([data-show-on-map="false"]) {
  cursor: pointer;
  background-color: var(--bs-gray-200);
}

.highlights-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.card-title-wrapper {
  position: relative;
  h3 {
    margin-top: 6px;
    // margin-bottom: 0;
  }
}

.social-links-wrappers {
  cursor: pointer;
}

.social-links-highlights {
  width: 100%;
  height: 30px;
  justify-content: space-between;
  align-items: center;
  margin-right: 15px;
  left: 0;
  z-index: 5;
  padding-right: 45px;
  background-color: #fff;
  svg {
    width: 24px;
    height: 24px;
    fill: #888;
    transition: fill 500ms linear;
    &:hover {
      fill: #3f3f3f;
    }
  }
}

.copy-link {
  cursor: pointer;
}

.h-copy {
  svg {
    pointer-events: none;
  }
}
.share-content {
  z-index: 9;
  height: 30px;
  svg {
    pointer-events: none;
    width: 30px;
    height: 30px;
    fill: #888;
  }
}

.player-on-range {
  max-height: 150px;
  overflow: auto;

  img,
  svg {
    width: 25px;
    height: 25px;
  }
}
